var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-overlay",
    {
      attrs: { center: "", show: _vm.isLoading, rounded: "sm" },
      scopedSlots: _vm._u([
        {
          key: "overlay",
          fn: function () {
            return [
              _c(
                "div",
                { staticClass: "text-center mt-5" },
                [
                  _c("base-icon", {
                    attrs: { name: "loading", width: "35", height: "35" },
                  }),
                  _vm._v(" "),
                  _c("p", { attrs: { id: "cancel-label" } }, [
                    _vm._v("Operazione in corso..."),
                  ]),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _vm.canVerb(_vm.resource, "index")
        ? _c(
            "div",
            { staticClass: "mx-1" },
            [
              _c("importer-form", {
                attrs: { config: _vm.config },
                on: {
                  upload: _vm.uploadFile,
                  delete: _vm.deleteFile,
                  init: _vm.startTransmission,
                },
              }),
            ],
            1
          )
        : _c(
            "div",
            { staticClass: "mt-3" },
            [
              _c("b-icon", { attrs: { icon: "info-circle", scale: "1.00" } }),
              _vm._v("\n    Non hai i permessi\n  "),
            ],
            1
          ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }