var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.config.length
    ? _c(
        "div",
        _vm._l(_vm.config, function (section, i) {
          return _c(
            "b-card",
            {
              key: `section-${i}`,
              staticClass: "mt-1",
              attrs: { header: section.category, "header-tag": "header" },
            },
            [
              _c(
                "b-overlay",
                {
                  attrs: { center: "", show: section.isLoading, rounded: "sm" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "overlay",
                        fn: function () {
                          return [
                            _c(
                              "div",
                              { staticClass: "text-center" },
                              [
                                _c("base-icon", {
                                  attrs: {
                                    name: "loading",
                                    width: "35",
                                    height: "35",
                                  },
                                }),
                                _vm._v(" "),
                                _c("p", { attrs: { id: "cancel-label" } }, [
                                  _vm._v("Operazione in corso..."),
                                ]),
                              ],
                              1
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    true
                  ),
                },
                _vm._l(section.upload, function (upload, j) {
                  return _c(
                    "b-card-text",
                    { key: `upload-${j}` },
                    [
                      _c(
                        "p",
                        {
                          directives: [
                            {
                              name: "b-toggle",
                              rawName: "v-b-toggle",
                              value: `${section.type}-${j}`,
                              expression: "`${section.type}-${j}`",
                            },
                          ],
                          attrs: { variant: "primary" },
                        },
                        [
                          _vm._v(
                            "\n          " + _vm._s(upload.title) + "\n        "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "b-collapse",
                        { attrs: { id: `${section.type}-${j}` } },
                        [
                          _vm._l(upload.files, function (file, k) {
                            return _c(
                              "b-card",
                              {
                                key: `files-${section.type}-${k}`,
                                staticClass: "mt-2",
                              },
                              [
                                _c(
                                  "b-overlay",
                                  {
                                    attrs: {
                                      center: "",
                                      show: file.isLoading,
                                      rounded: "sm",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "overlay",
                                          fn: function () {
                                            return [
                                              _c(
                                                "div",
                                                { staticClass: "text-center" },
                                                [
                                                  _c("base-icon", {
                                                    attrs: {
                                                      name: "loading",
                                                      width: "35",
                                                      height: "35",
                                                    },
                                                  }),
                                                  _vm._v(" "),
                                                  _c(
                                                    "p",
                                                    {
                                                      attrs: {
                                                        id: "cancel-label",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "Operazione in corso..."
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  },
                                  [
                                    _c(
                                      "b-card-text",
                                      [
                                        _c("b-table", {
                                          attrs: {
                                            fields: _vm.fields,
                                            items: [file],
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "cell(title)",
                                                fn: function (data) {
                                                  return [
                                                    _vm._v(
                                                      "\n                    " +
                                                        _vm._s(
                                                          data.item
                                                            .import_file_code
                                                        ) +
                                                        "\n                  "
                                                    ),
                                                  ]
                                                },
                                              },
                                              {
                                                key: "cell(filesystem)",
                                                fn: function (data) {
                                                  return [
                                                    _c(
                                                      "div",
                                                      { staticClass: "mt-3" },
                                                      [
                                                        _c("base-form-file", {
                                                          attrs: {
                                                            vid: "base_form_file",
                                                            disabled:
                                                              data.item
                                                                .isStored,
                                                          },
                                                          model: {
                                                            value:
                                                              data.item.value,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                data.item,
                                                                "value",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "data.item.value",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                },
                                              },
                                              {
                                                key: "cell(upload)",
                                                fn: function (data) {
                                                  return [
                                                    _c(
                                                      "b-button",
                                                      {
                                                        attrs: {
                                                          variant: "lisaweb",
                                                          size: "sm",
                                                          disabled:
                                                            data.item
                                                              .isStored ||
                                                            !data.item.value,
                                                          title: "Carica File",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.uploadFile(
                                                              data,
                                                              upload
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                      Carica\n                    "
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                },
                                              },
                                              {
                                                key: "cell(delete)",
                                                fn: function (data) {
                                                  return [
                                                    _c(
                                                      "b-button",
                                                      {
                                                        attrs: {
                                                          size: "sm",
                                                          variant: "lisaweb",
                                                          disabled:
                                                            !data.item.isStored,
                                                          title:
                                                            "Elimina file caricato",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.deleteFile(
                                                              data
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                      Elimina\n                    "
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                },
                                              },
                                              {
                                                key: "cell(check)",
                                                fn: function (data) {
                                                  return [
                                                    data.item.isStored
                                                      ? _c("b-icon", {
                                                          attrs: {
                                                            icon: "check-square",
                                                            scale: "2",
                                                            variant: "success",
                                                            title:
                                                              "File caricato",
                                                          },
                                                        })
                                                      : _vm._e(),
                                                    _vm._v(" "),
                                                    !data.item.isStored
                                                      ? _c("b-icon", {
                                                          attrs: {
                                                            icon: "x-circle",
                                                            scale: "2",
                                                            variant: "danger",
                                                            title:
                                                              "File non caricato",
                                                          },
                                                        })
                                                      : _vm._e(),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          }),
                          _vm._v(" "),
                          _c(
                            "b-button",
                            {
                              staticClass: "mt-2",
                              attrs: {
                                size: "sm",
                                variant: "lisaweb",
                                title: "Inizia Trasmissione",
                                disabled: !_vm.checkStoredFiles(upload),
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.init(section, upload)
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n            Inizia Trasmissione\n          "
                              ),
                            ]
                          ),
                        ],
                        2
                      ),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          )
        }),
        1
      )
    : _c("div", [_c("p", [_vm._v("Non ci sono file da importare")])])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }