var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "mt-1 mx-3" }, [
    _vm.tabs.length
      ? _c(
          "div",
          [
            _c(
              "b-tabs",
              {
                attrs: { "content-class": "pt-1", lazy: "" },
                model: {
                  value: _vm.tabIndex,
                  callback: function ($$v) {
                    _vm.tabIndex = $$v
                  },
                  expression: "tabIndex",
                },
              },
              _vm._l(_vm.tabs, function (tab, index) {
                return _c(
                  "b-tab",
                  {
                    key: index,
                    attrs: {
                      title: tab.text,
                      active: _vm.active(index),
                      "title-item-class": "lisaweb",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.onTabClick(tab, index)
                      },
                    },
                  },
                  [
                    _c(
                      "keep-alive",
                      [_c("importer", { attrs: { importer: tab } })],
                      1
                    ),
                  ],
                  1
                )
              }),
              1
            ),
          ],
          1
        )
      : _c("div", [_vm._v("Non ci sono importatori attivi")]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }